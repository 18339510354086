import React from "react"
import Layout from "@yumgmbh/gatsby-theme-yum-components/src/components/modules/Layout"

const LoginPage = () => {
  return (
    <Layout>
      <div>Absprung-Seite zum Login</div>
    </Layout>
  )
}

export default LoginPage
